import { createClient, defaultExchanges, subscriptionExchange } from "urql";
import { createClient as createWSClient } from "graphql-ws";

interface GQLSocket {
  readyState: number;
  close: Function;
}

const wsURL = process.env.REACT_APP_SECURE
  ? `wss://${window?.location?.host}/ws`
  : `ws://localhost:3002/ws`;

let activeSocket: GQLSocket;
let timedOut: NodeJS.Timeout;
let pingSentAt = 0;
let latency = 0;

const wsClient = createWSClient({
  url: wsURL,
  keepAlive: 10_000,
  on: {
    opened: (socket) => (activeSocket = socket as GQLSocket),
    ping: (received) => {
      if (!received) {
        pingSentAt = Date.now();
        timedOut = setTimeout(() => {
          if (activeSocket.readyState === WebSocket.OPEN)
            activeSocket.close(4408, "Request Timeout");
        }, 5_000);
      }
    },
    pong: (received) => {
      if (received) {
        latency = Date.now() - pingSentAt;
        clearTimeout(timedOut);
      }
    },
  },
});

export const client = createClient({
  url: "/graphql",
  fetchOptions: () => ({
    credentials: "include",
  }),
  exchanges: [
    ...defaultExchanges,
    subscriptionExchange({
      forwardSubscription(operation) {
        return {
          subscribe: (sink) => {
            const dispose = wsClient.subscribe(operation, sink);
            return {
              unsubscribe: dispose,
            };
          },
        };
      },
    }),
  ],
});
