import { useContext, ReactElement, useEffect } from "react";

import { Redirect } from "react-router-dom";

import { UserControlContext } from "../components/context/UserControlStore";

const Logout = (): ReactElement => {
  const { signOutUser } = useContext(UserControlContext);

  useEffect(() => {
    signOutUser();
  }, [signOutUser]);

  return <Redirect to="/" />;
};

export default Logout;
