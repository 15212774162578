import { ReactElement } from "react";

import styled from "styled-components";

const Container = styled.footer`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  text-align: center;
  font-size: 0.65rem;
`;

const Footer = (): ReactElement => (
  <Container>
    <p>
      A <i>Sharp Thoughts</i>™ production in <strong>super cooperation</strong>{" "}
      with Flancrest Enterprises,
    </p>
    <p>
      <i>El Jefe PLC</i>
    </p>
    <p>Quality Assurance by The Helen/Alice Brand Alliance ©</p>
  </Container>
);

export default Footer;
