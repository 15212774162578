import styled from "styled-components";

const BlockButton = styled.button`
  display: block;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.25);
  margin: 0.5rem;
  border: 2px solid #333;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  padding: 0.5rem 1.25rem;
  transition: background 0.25s ease-in-out;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }
`;

export default BlockButton;
