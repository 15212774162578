export const ACTIVE_USERS_SUBSCRIPTION = `
  subscription ActiveUsers {
    activeUsers {
      id
      name
      avatar
    }
  }
`;

export const ROUNDS_IN_PROGRESS_SUBSCRIPTION = `
  subscription ActiveRounds {
    activeRounds {
      id
      letter
      started_at
      finishes_at
      winner_id
      created_by
      final_score
    }
  }
`;

export const ROUND_PROGRESS_SUBSCRIPTION = `
  subscription RoundProgress($id: String!) {
    roundProgress(id: $id) {
      id
      letter
      started_at
      finishes_at
      winner_id
      created_by
      final_score
    }
  }
`;

export const VOTE_SUBMITTED_SUBSCRIPTION = `
  subscription VoteSubmitted($id: String!) {
    voteSubmitted(id: $id) {
      id
      user_id
      round_id
      question_id
      string
      voted_for_by
      score
      scored
    }
  }
`;
