import { useState, useEffect, ReactElement, KeyboardEvent } from "react";

import styled from "styled-components";

import { useMutation } from "urql";

import { SUBMIT_ANSWERS_MUTATION } from "../../graphql/mutations";

import Question from "../atoms/Question";

import { GQLQuestion } from "../../../../types";

interface Props {
  letter: string;
  questions: GQLQuestion[];
  round: string;
}

const Table = styled.table`
  text-align: left;
  padding: 0.75rem 2.5rem;
  padding-bottom: 10vh;
  width: 100%;

  @media only screen and (max-width: 768px) {
    & {
      padding: 0.75rem 1.25rem;
      padding-bottom: 12.5vh;
    }
  }
`;

const Column = styled.th`
  &:first-of-type {
    width: 150px;
  }

  &:last-of-type {
    width: calc(100% - 150px);
  }
`;

const Questions = ({ letter, questions, round }: Props): ReactElement => {
  const [answers, setAnswers] = useState({});

  const [, submitAnswers] = useMutation(SUBMIT_ANSWERS_MUTATION);

  const setAnswer = ({
    questionId,
    answer,
  }: {
    questionId: string;
    answer: string;
  }) =>
    setAnswers((prev) => ({
      ...prev,
      [questionId]: { question_id: questionId, answer },
    }));

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    const allInputs = document.querySelectorAll("[data-tag='question-input']");

    if (e.key === "ArrowDown") {
      if (allInputs[index + 1]) {
        (allInputs[index + 1] as HTMLElement).focus();
      }
    }

    if (e.key === "ArrowUp") {
      if (allInputs[index - 1]) {
        (allInputs[index - 1] as HTMLElement).focus();
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (answers) {
        await submitAnswers({ id: round, answers: Object.values(answers) });
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [round, answers, submitAnswers]);

  return (
    <Table>
      <thead>
        <tr>
          <Column>Category</Column>
          <Column>Answer</Column>
        </tr>
      </thead>
      <tbody>
        {questions &&
          questions.map((question, i) => (
            <Question
              key={i}
              index={i}
              letter={letter}
              onChange={setAnswer}
              onKeyDown={onKeyDown}
              question={question}
            />
          ))}
      </tbody>
    </Table>
  );
};

export default Questions;
