import { ReactElement } from "react";

import styled from "styled-components";

export interface AvatarProps {
  src?: string;
  isMini?: boolean;
}

const StyledAvatar = styled.div<{ src: string; isMini: boolean }>`
  border-radius: 100%;
  background: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ isMini }) => (isMini ? "25px" : "150px")};
  width: ${({ isMini }) => (isMini ? "25px" : "150px")};
`;

const Avatar = ({ src, isMini = false }: AvatarProps): ReactElement => (
  <StyledAvatar
    src={
      src
        ? `data:image/png;base64,${src}`
        : "https://via.placeholder.com/150x150?text=?"
    }
    isMini={isMini}
  />
);

export default Avatar;
