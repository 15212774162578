import { useEffect, useState, ReactElement } from "react";

interface CountdownProps {
  ends: string;
  onRoundEnded: () => void;
}

const Countdown = ({ ends, onRoundEnded }: CountdownProps): ReactElement => {
  const [timeLeft, updateTimeLeft] = useState(
    Math.max(new Date(parseInt(ends)).getTime() - new Date().getTime(), 0)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const newTime = Math.max(
        new Date(parseInt(ends)).getTime() - new Date().getTime(),
        0
      );

      if (newTime === 0) {
        onRoundEnded();
      }

      updateTimeLeft(newTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [ends, onRoundEnded]);

  return (
    <div>
      Round ends in {new Date(timeLeft).toISOString().substring(14, 19)}
    </div>
  );
};

export default Countdown;
