import { ReactElement } from "react";

import styled from "styled-components";

import { useMutation } from "urql";

import { VOTE_FOR_ANSWER_MUTATION } from "../../graphql/mutations";

import { GQLUser, GQLAnswer } from "../../../../types";

interface AnswerProps {
  answer: GQLAnswer;
  user: GQLUser;
}

const Cell = styled.td`
  background-color: #f7f7f7;
  border: 1px solid black;
  padding: 0.25rem;
`;

const Vote = styled(Cell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Disabled = styled(Vote)`
  border: 1px solid #c16c6c;
  color: #6f6f6f;
`;

const Button = styled.button`
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
`;

const Answer = ({
  user,
  answer: {
    id,
    string,
    question: { id: question_id, category },
    score,
    scored,
    user: { id: user_id },
    round_id,
  },
}: AnswerProps): ReactElement => {
  const [, voteForAnswer] = useMutation(VOTE_FOR_ANSWER_MUTATION);

  const computedScore: string | number = scored ? score : "-";

  const isCurrentUser = user.id === user_id;

  const givePoints = async () =>
    voteForAnswer({ roundId: round_id, id, questionId: question_id });

  const zeroPoints = async () =>
    voteForAnswer({
      roundId: round_id,
      id,
      questionId: question_id,
      wrong: true,
    });

  return (
    <tr data-id={id}>
      <Cell>{category}</Cell>
      <Cell>{string}</Cell>
      {isCurrentUser ? (
        <Disabled>{computedScore}</Disabled>
      ) : (
        <Vote>
          {computedScore}
          <div>
            <Button onClick={givePoints}>✔</Button>
            <Button onClick={zeroPoints}>✘</Button>
          </div>
        </Vote>
      )}
    </tr>
  );
};

export default Answer;
