import { ReactElement, useEffect } from "react";
import { useHistory } from "react-router-dom";
import uniqBy from "lodash.uniqby";

import styled from "styled-components";

import { useQuery, useMutation, useSubscription } from "urql";

import {
  GET_ROUNDS_IN_PROGRESS_QUERY,
  GET_ACTIVE_USERS_QUERY,
} from "../graphql/queries";

import { CREATE_ROUND_MUTATION } from "../graphql/mutations";
import {
  ACTIVE_USERS_SUBSCRIPTION,
  ROUNDS_IN_PROGRESS_SUBSCRIPTION,
} from "../graphql/subscriptions";

import Users from "../components/molecules/Users";
import Rounds from "../components/molecules/Rounds";

import Header from "../components/atoms/Header";

import { GQLRound, GQLUser } from "../../../types";

export interface LandingProps {
  user: GQLUser;
}

const Container = styled.section`
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
  padding: 0 5rem;

  @media only screen and (max-width: 768px) {
    & {
      padding: 0 2.5rem;
    }
  }
`;

const Landing = ({ user }: LandingProps): ReactElement => {
  const { push } = useHistory();

  const [{ data: activeUserSubscriptionData }] = useSubscription(
    { query: ACTIVE_USERS_SUBSCRIPTION },
    (messages: any = [], response: { activeUsers: GQLUser[] }) => {
      return [...response?.activeUsers, ...messages];
    }
  );

  const [{ data: activeUserQueryData }] = useQuery({
    query: GET_ACTIVE_USERS_QUERY,
    requestPolicy: "network-only",
  });

  const [{ data: roundsSubscriptionData }] = useSubscription(
    { query: ROUNDS_IN_PROGRESS_SUBSCRIPTION },
    (messages: any = [], response: { activeRounds: GQLRound[] }) => {
      return [...response?.activeRounds, ...messages];
    }
  );

  const [{ data: roundsQueryData }] = useQuery({
    query: GET_ROUNDS_IN_PROGRESS_QUERY,
    requestPolicy: "network-only",
  });

  const [newRound, startRound] = useMutation(CREATE_ROUND_MUTATION);

  const onClick = async () => {
    try {
      await startRound();
    } catch (error) {
      console.info({ error });
    }
  };

  const activeUsers = uniqBy(
    [
      ...(activeUserSubscriptionData ?? []),
      ...(activeUserQueryData?.getActiveUsers ?? []),
    ],
    (user) => user?.id
  );

  const activeRounds = uniqBy(
    [
      ...(roundsQueryData?.getRoundsInProgress ?? []),
      ...(roundsSubscriptionData ?? []),
    ],
    (round) => round?.id
  );

  useEffect(() => {
    if (newRound.data?.createRound) {
      push(`/round/${newRound.data?.createRound?.id}`);
    }
  }, [newRound?.data, push]);

  return (
    <>
      <Header />
      <Container>
        <h2>
          hi <strong>{user && user?.name}</strong>
        </h2>
        <Rounds rounds={activeRounds} onClick={onClick} />
        <Users users={activeUsers as GQLUser[]} />
      </Container>
    </>
  );
};

export default Landing;
