import { ReactElement } from "react";

import { format } from "date-fns";

import styled from "styled-components";

import { GQLRound } from "../../../../types";

interface Props {
  scores: GQLRound[];
  user: string;
}

const Table = styled.table`
  font-size: 1.15rem;
  margin: 1.15rem 0;
  text-align: left;
  width: 100%;
`;

const Header = styled.th`
  background: #fff;
  border: 1px solid #ddd;
  padding: 0.4rem;
`;

const Cell = styled.td`
  background: #fff;
  border: 1px solid #ddd;
  padding: 0.4rem;
`;

const History = ({ scores, user }: Props): ReactElement => {
  return (
    <>
      <div>
        <Table>
          <thead>
            <tr>
              <Header>Date</Header>
              <Header>Letter</Header>
              <Header>Winner</Header>
              <Header>Score</Header>
            </tr>
          </thead>
          <tbody>
            {scores.map((score) => (
              <tr key={score.id}>
                <Cell>
                  {format(
                    new Date(parseInt(score.started_at as string)),
                    "PPPP HH:mm"
                  )}
                </Cell>
                <Cell>{score.letter}</Cell>
                <Cell>
                  {score.winner?.id === user ? "You" : score.winner?.name}
                </Cell>
                <Cell>{score.final_score}</Cell>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default History;
