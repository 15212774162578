import { ReactElement } from "react";

import User from "../atoms/User";

import { GQLUser } from "../../../../types";

interface Props {
  users: GQLUser[];
}

const Users = ({ users }: Props): ReactElement => (
  <div>
    <h3>👋</h3>
    <div>
      {users && users.length
        ? users.map(({ id, name, avatar }) => (
            <User key={id} name={name as string} avatar={avatar} />
          ))
        : null}
    </div>
  </div>
);

export default Users;
