import { ReactElement, useMemo } from "react";

import { useHistory, Redirect, RouteComponentProps } from "react-router-dom";
import { format } from "date-fns";

import { useQuery, useMutation, useSubscription } from "urql";

import styled from "styled-components";
import readable from "uuid-readable";

import Questions from "../components/molecules/Questions";

import Countdown from "../components/atoms/Countdown";
import Header from "../components/atoms/Header";
import BlockButton from "../components/atoms/BlockButton";

import { GET_ROUND_BY_ID_QUERY } from "../graphql/queries";
import { START_ROUND_MUTATION, END_ROUND_MUTATION } from "../graphql/mutations";
import { ROUND_PROGRESS_SUBSCRIPTION } from "../graphql/subscriptions";

import { GQLUser, GQLRound } from "../../../types";

interface Props extends RouteComponentProps<{ id: string }> {
  user: GQLUser;
}

// Very scientific there Mark
const LENGTH_OF_A_UUID = 36;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Info = styled.div`
  @media only screen and (max-width: 768px) {
    padding: 0 0.5rem;
  }
`;

const H1 = styled.h1`
  display: block;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 1.5rem;
    }
  }
`;

const H2 = styled.h2`
  display: block;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 1rem;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Round = ({ user, match }: Props): ReactElement => {
  let { id } = match.params;

  const { push } = useHistory();

  // It's not a UUID, maybe somebody used one of our human-readable URLs?
  if (id.length !== LENGTH_OF_A_UUID) {
    try {
      id = readable.inverse(id);
    } catch (error) {
      console.warn("I can't let you do that Hal");
      id = "";
    }
  }

  const [{ data: roundProgressSubscriptionData }] = useSubscription(
    { query: ROUND_PROGRESS_SUBSCRIPTION, variables: { id } },
    (messages: any = [], response: { roundProgress: any }) => {
      return response?.roundProgress ?? {};
    }
  );

  const [{ data: roundQueryData }, refetch] = useQuery({
    query: GET_ROUND_BY_ID_QUERY,
    variables: { id },
    requestPolicy: "network-only",
  });

  const [, startRound] = useMutation(START_ROUND_MUTATION);
  const [, endRound] = useMutation(END_ROUND_MUTATION);

  const round: GQLRound = {
    ...roundQueryData?.getRoundById,
    ...roundProgressSubscriptionData,
  };

  const onStartRound = async () => {
    await startRound({ id });

    refetch({
      requestPolicy: "network-only",
    });
  };

  const onEndRound = async () => {
    await endRound({ id });

    refetch({
      requestPolicy: "network-only",
    });

    push(`/round/${id}/results`);
  };

  const onRoundEnded = () => push(`/round/${id}/results`);

  const notFinished = useMemo(
    () =>
      round?.finishes_at
        ? new Date(parseInt(round?.finishes_at)).getTime() >
          new Date().getTime()
        : true,
    [round?.finishes_at]
  );

  const statusMessage = round?.started_at
    ? `started at ${format(
        new Date(parseInt(round?.started_at as string)),
        "HH:mm"
      )}`
    : "hasn't started yet";

  const userOwnsRound = user && round?.creator?.id === user.id;

  const canStartRound = !round?.started_at && userOwnsRound;
  const canEndRound = round?.started_at && userOwnsRound;

  return notFinished ? (
    <>
      <Header />
      <Wrapper>
        <Info>
          <h5>
            <strong>Round:</strong>{" "}
            {id.length === LENGTH_OF_A_UUID ? readable.generate(id) : id}
          </h5>
          <H1>
            The letter is{" "}
            <strong>{round?.started_at ? round?.letter : "?"}</strong>
          </H1>
          <H2>
            This round was created by <strong>{round?.creator?.name}</strong>{" "}
            and <strong>{statusMessage}</strong>
          </H2>
          {round?.finishes_at && (
            <Countdown
              ends={round?.finishes_at as string}
              onRoundEnded={onRoundEnded}
            />
          )}
          {canStartRound && (
            <Buttons>
              <BlockButton type="button" onClick={onStartRound}>
                Start round
              </BlockButton>
            </Buttons>
          )}
          {canEndRound && (
            <Buttons>
              <BlockButton
                type="button"
                onClick={onEndRound}
                style={{ marginTop: "2.25em" }}
              >
                End round
              </BlockButton>
            </Buttons>
          )}
        </Info>
        {round?.started_at && (
          <Questions
            round={id}
            letter={round?.letter}
            questions={round?.questions}
          />
        )}
      </Wrapper>
    </>
  ) : (
    <Redirect to={`/round/${id}/results`} />
  );
};

export default Round;
