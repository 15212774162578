export const CREATE_ROUND_MUTATION = `
  mutation CreateRound {
    createRound {
      id
      letter
      started_at
      finishes_at
      winner_id
      created_by
      final_score
      questions {
        id
        round_id
        category
        direction
      }
    }
  }
`;

export const START_ROUND_MUTATION = `
  mutation StartRound($id: String!) {
    startRound(id: $id) {
      id
      letter
      started_at
      finishes_at
      winner_id
      created_by
      final_score
      questions {
        id
        round_id
        category
        direction
      }
    }
  }
`;

export const END_ROUND_MUTATION = `
  mutation EndRound($id: String!) {
    endRound(id: $id) {
      id
      letter
      started_at
      finishes_at
      winner_id
      created_by
      final_score
    }
  }
`;

export const SUBMIT_ANSWERS_MUTATION = `
  mutation SubmitAnswers($id: String!, $answers: [Guess!]) {
    submitAnswers(id: $id, answers: $answers) {
      id
    }
  }
`;

export const VOTE_FOR_ANSWER_MUTATION = `
  mutation VoteForAnswer(
    $id: String!
    $roundId: String!
    $questionId: String!
    $wrong: Boolean
  ) {
    vote(
      id: $id
      roundId: $roundId
      questionId: $questionId
      wrong: $wrong
    ) {
      id
    }
  } 
`;

export const DECLARE_WINNER_MUTATION = `
  mutation DeclareWinner($id: String!) {
    declareWinnerOfRound(id: $id) {
      user_id
      name
      total
    }
  }
`;
