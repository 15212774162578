import { useContext, useState, ChangeEvent, ReactElement } from "react";
import { useHistory, Redirect } from "react-router-dom";

import styled from "styled-components";

import { useQuery } from "urql";

import { GET_USER_QUERY } from "../graphql/queries";

import { UserControlContext } from "../components/context/UserControlStore";

import Footer from "../components/atoms/Footer";
import BlockButton from "../components/atoms/BlockButton";
import Error from "../components/atoms/Error";

import FishFruitLogo from "../assets/fishfruitflowers-logo.png";
import FishFruitBackground from "../assets/fishfruitflowers-background.png";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  height: auto;
  width: 80vw;
  max-width: 700px;

  @media only screen and (max-width: 768px) {
    & {
      width: 96vw;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 500px;

  @media only screen and (max-width: 768px) {
    & {
      width: 80vw;
    }
  }
`;

const Label = styled.label`
  display: block;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  margin: 0.75rem 0;
  border: 1px dotted #333;
  border-radius: 5px;
  outline: none;
  padding: 0.75rem;
  font-size: 1rem;
`;

const Login = (): ReactElement => {
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const { signInUser } = useContext(UserControlContext);
  const { push } = useHistory();

  const updateName = (e: ChangeEvent<HTMLInputElement>) => {
    setError("");
    setName(e.target.value);
  };

  const updatePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setError("");
    setPassword(e.target.value);
  };

  const [{ data }] = useQuery({
    query: GET_USER_QUERY,
  });

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setError("");

    if (name && password) {
      try {
        await signInUser(name, password);

        push("/");
      } catch (error) {
        setError((error as Error).message as string);
      }
    } else {
      setError("Please provide a name and password");
    }
  };

  return data?.getUser ? (
    <Redirect to="/" />
  ) : (
    <>
      <Wrapper style={{ backgroundImage: `url(${FishFruitBackground})` }}>
        <Image src={FishFruitLogo} alt="fish-fruit-flowers" />
        <Form onSubmit={onSubmit}>
          <Label>
            PLZ ENTER YOUR NAME:
            <Input
              type="text"
              placeholder="Your name, plz"
              onChange={updateName}
            />
          </Label>
          <Label>
            PLZ ENTER A PASSWORD:
            <Input
              type="password"
              placeholder="A password, plz"
              onChange={updatePassword}
            />
          </Label>
          <BlockButton type="submit" onClick={onSubmit}>
            Login
          </BlockButton>
        </Form>
      </Wrapper>
      {error ? <Error message={error} /> : null}
      <Footer />
    </>
  );
};

export default Login;
