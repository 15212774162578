import { ReactElement } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import BlockButton from "./BlockButton";

import FishFruitHeader from "../../assets/fishfruitflowers-header.png";

const sessionCookie = "FishFruitFlowersSessionId";

const Background = styled.header`
  background-image: url("${FishFruitHeader}");
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 16vw;
  width: 100vw;
  background-size: cover;
  z-index: -1;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
`;

const Header = (): ReactElement => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const logOut = () => {
    document.cookie = `${sessionCookie}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    localStorage.clear();
    window.location.href = "/login";
  };

  const atHome = pathname === "/";

  const goTo = () => (atHome ? push("/me") : push("/"));

  return (
    <>
      <Background />
      <Buttons>
        <BlockButton onClick={goTo}>{atHome ? "Me" : "Home"}</BlockButton>
        <BlockButton onClick={logOut}>Log Out</BlockButton>
      </Buttons>
    </>
  );
};

export default Header;
