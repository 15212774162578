import { ReactElement } from "react";

import styled, { keyframes } from "styled-components";

interface Props {
  cheating: boolean;
  winners: string;
  noOfWinners: number;
  winningScore: number;
}

const rainbow = keyframes`
  0%, 100% {
    transform: translatey(1.5rem);
  }
  50% {
    transform: translatey(calc(1.5rem * -1));
  }
`;

const Podium = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 25vh;
  width: 100vw;
  margin-top: 10vh;
`;

const Wrapper = styled.ul`
  counter-reset: ${rainbow};
  position: relative;
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  z-index: 1;
`;

const Bow = styled.li`
  counter-increment: ${rainbow};
  font-size: 3.5rem;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000, 4px 4px 0 rgba(0, 0, 0, 0.2);
  animation: ${rainbow} 1.5s ease-in-out infinite;
  position: absolute;
  right: 0;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 2.5rem;
      padding: 0 1rem;
    }
  }

  &:nth-child(1) {
    animation-delay: calc(1 / 10 * 1s);
    left: calc(1px * 1);
    z-index: -10;
  }

  &:nth-child(2) {
    animation-delay: calc(2 / 10 * 1s);
    left: calc(1px * 2);
    z-index: -20;
  }

  &:nth-child(3) {
    animation-delay: calc(3 / 10 * 1s);
    left: calc(1px * 3);
    z-index: -30;
  }

  &:nth-child(4) {
    animation-delay: calc(4 / 10 * 1s);
    left: calc(1px * 4);
    z-index: -40;
  }

  &:nth-child(5) {
    animation-delay: calc(5 / 10 * 1s);
    left: calc(1px * 5);
    z-index: -50;
  }

  &:nth-child(6) {
    animation-delay: calc(6 / 10 * 1s);
    left: calc(1px * 6);
    z-index: -60;
  }

  &:nth-child(7) {
    animation-delay: calc(7 / 10 * 1s);
    left: calc(1px * 7);
    z-index: -70;
  }

  &::not(:first-child) {
    position: absolute;
    top: 0;
  }
`;

const White = styled(Bow)`
  color: #fff;
`;

const Orange = styled(Bow)`
  color: #d49c3d;
`;

const Red = styled(Bow)`
  color: #d14b3d;
`;

const Violet = styled(Bow)`
  color: #cf52eb;
`;

const Blue = styled(Bow)`
  color: #44a3f7;
`;

const Green = styled(Bow)`
  color: #5acb3c;
`;

const Yellow = styled(Bow)`
  color: #debf40;
`;

const Rainbow = ({
  cheating,
  winners,
  noOfWinners,
  winningScore,
}: Props): ReactElement => (
  <Podium>
    {cheating ? (
      <Wrapper>
        <White>NO ONE WON, OH THE HUMANITY</White>
        <Orange>NO ONE WON, OH THE HUMANITY</Orange>
        <Red>NO ONE WON, OH THE HUMANITY</Red>
        <Violet>NO ONE WON, OH THE HUMANITY</Violet>
        <Blue>NO ONE WON, OH THE HUMANITY</Blue>
        <Green>NO ONE WON, OH THE HUMANITY</Green>
        <Yellow>NO ONE WON, OH THE HUMANITY</Yellow>
      </Wrapper>
    ) : (
      <Wrapper>
        <White>
          {winners} {`${noOfWinners > 1 ? "HAVE" : "HAS"}`} WON WITH A
          RESPECTABLE {winningScore} POINTS!!!!
        </White>
        <Orange>
          {winners} {`${noOfWinners > 1 ? "HAVE" : "HAS"}`} WON WITH A
          RESPECTABLE {winningScore} POINTS!!!!
        </Orange>
        <Red>
          {winners} {`${noOfWinners > 1 ? "HAVE" : "HAS"}`} WON WITH A
          RESPECTABLE {winningScore} POINTS!!!!
        </Red>
        <Violet>
          {winners} {`${noOfWinners > 1 ? "HAVE" : "HAS"}`} WON WITH A
          RESPECTABLE {winningScore} POINTS!!!!
        </Violet>
        <Blue>
          {winners} {`${noOfWinners > 1 ? "HAVE" : "HAS"}`} WON WITH A
          RESPECTABLE {winningScore} POINTS!!!!
        </Blue>
        <Green>
          {winners} {`${noOfWinners > 1 ? "HAVE" : "HAS"}`} WON WITH A
          RESPECTABLE {winningScore} POINTS!!!!
        </Green>
        <Yellow>
          {winners} {`${noOfWinners > 1 ? "HAVE" : "HAS"}`} WON WITH A
          RESPECTABLE {winningScore} POINTS!!!!
        </Yellow>
      </Wrapper>
    )}
  </Podium>
);

export default Rainbow;
