import { ReactElement } from "react";

import { Redirect, RouteComponentProps } from "react-router-dom";

import styled from "styled-components";
import readable from "uuid-readable";

import { useQuery, useMutation, useSubscription } from "urql";

import { GET_ANSWERS_FOR_ROUND_QUERY } from "../graphql/queries";
import { DECLARE_WINNER_MUTATION } from "../graphql/mutations";
import { ROUND_PROGRESS_SUBSCRIPTION } from "../graphql/subscriptions";

import Answers from "../components/molecules/Answers";

import Header from "../components/atoms/Header";
import BlockButton from "../components/atoms/BlockButton";

import { GQLRound, GQLUser } from "../../../types";

interface Props extends RouteComponentProps<{ id: string }> {
  user: GQLUser;
}

// Very scientific there Mark
const LENGTH_OF_A_UUID = 36;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Results = ({ match, user }: Props): ReactElement => {
  const { id } = match.params;

  const [{ data: roundQueryData }] = useQuery({
    query: GET_ANSWERS_FOR_ROUND_QUERY,
    variables: { id },
  });

  const [{ data: roundProgressSubscriptionData }] = useSubscription(
    { query: ROUND_PROGRESS_SUBSCRIPTION, variables: { id } },
    (messages: any = [], response: { roundProgress: any }) => {
      return response?.roundProgress ?? {};
    }
  );

  const [, declareWinner] = useMutation(DECLARE_WINNER_MUTATION);

  const endRound = () => declareWinner({ id });

  const round: GQLRound = {
    ...roundQueryData?.getRoundById,
    ...roundProgressSubscriptionData,
  };

  const userOwnsRound = user?.id === roundQueryData?.getRoundById?.creator?.id;

  return round?.winner?.id || round?.winner_id ? (
    <Redirect to={`/round/${id}/winner`} />
  ) : (
    <>
      <Header />
      <Wrapper>
        <div>
          <h5>
            <strong>Round:</strong>{" "}
            {id.length === LENGTH_OF_A_UUID ? readable.generate(id) : id}
          </h5>
          <h1>
            The letter was <strong>{round?.letter}</strong>
          </h1>
          <h2>
            This round was created by <strong>{round?.creator?.name}</strong>{" "}
            and <strong>has finished</strong>
          </h2>
          {userOwnsRound ? (
            <BlockButton style={{ display: "initial" }} onClick={endRound}>
              {round?.marking?.length
                ? "Declare winner?"
                : "End this grim charade"}
            </BlockButton>
          ) : null}
        </div>
        {round?.marking?.length ? (
          <Answers round={round?.id} user={user} answers={round?.marking} />
        ) : (
          <h1>No one answered anything</h1>
        )}
      </Wrapper>
    </>
  );
};

export default Results;
