import { useState, useContext, useRef, ReactElement, useEffect } from "react";

import styled from "styled-components";

import { useQuery } from "urql";

import { GET_USER_HISTORY_QUERY } from "../graphql/queries";

import { UserControlContext } from "../components/context/UserControlStore";

import Header from "../components/atoms/Header";
import Avatar from "../components/atoms/Avatar";
import BlockButton from "../components/atoms/BlockButton";

import History from "../components/molecules/History";

const UserSection = styled.section`
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(8px + 2vmin);
  padding: 0 5rem;

  @media only screen and (max-width: 768px) {
    & {
      padding: 0 2.5rem;
    }
  }
`;

const ImageUploader = styled.div`
  padding: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div {
    padding-bottom: 1rem;
  }

  input {
    cursor: pointer;
    padding-bottom: 2rem;
    width: 100%;
  }
`;

const Profile = (): ReactElement => {
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [hasUploaded, setHasUploaded] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const ref = useRef();

  const [{ data }, refetch] = useQuery({
    query: GET_USER_HISTORY_QUERY,
  });

  const { uploadAvatar } = useContext(UserControlContext);

  const onChange = (e: { target: { files: FileList | null } }) => {
    if (e.target?.files?.length) {
      setHasFile(true);
    } else {
      setHasFile(false);
    }
  };

  const uploadUserAvatar = async () => {
    if (ref.current) {
      const file = (ref.current as any).files?.[0];

      if (file) {
        setIsUploading(true);
        setHasUploaded(false);

        const filesize = ((file?.size ?? 0) / 1024 / 1024).toFixed(4);

        if (parseInt(filesize) >= 40) {
          alert("UR FILE IS TOO BIG PLZ");
          return null;
        }

        try {
          await uploadAvatar(file as File);
          setHasUploaded(true);
        } catch (error) {
          alert(`Tell Mark this happened: ${(error as Error).message}`);
        } finally {
          setIsUploading(false);
          setHasFile(false);
        }
      }
    }
  };

  useEffect(() => {
    if (hasUploaded) {
      refetch({
        requestPolicy: "network-only",
      });
    }
  }, [hasUploaded, refetch]);

  return (
    <>
      <Header />
      <UserSection>
        <h2>
          heya <strong>{data?.getUser && data?.getUser.name}</strong>
        </h2>
        <Avatar src={data?.getUser?.avatar} />
        <ImageUploader>
          <div>Select a picture of your face (or a dog): </div>
          <input
            ref={ref as any}
            type="file"
            accept="image/png, image/jpeg"
            onChange={onChange}
          />
          {isUploading ? (
            <BlockButton>Loading...</BlockButton>
          ) : (
            <BlockButton disabled={!hasFile} onClick={uploadUserAvatar}>
              Upload
            </BlockButton>
          )}
        </ImageUploader>
        {data?.getUser?.scores && data?.getUser?.scores.length > 0 ? (
          <>
            <p>here's how the cookie crumbled for you:</p>
            <History user={data.getUser.id} scores={data.getUser.scores} />
          </>
        ) : null}
      </UserSection>
    </>
  );
};

export default Profile;
