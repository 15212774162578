import { ReactElement, ChangeEvent, KeyboardEvent } from "react";

import styled from "styled-components";

import { GQLQuestion } from "../../../../types";

interface QuestionProps {
  letter: string;
  question: GQLQuestion;
  index: number;
  onChange: (args: { questionId: string; answer: string }) => void;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>, index: number) => void;
}

const Input = styled.input`
  border: 1px solid #ddd;
  font-size: 1rem;
  outline: none;
  width: 100%;
`;

const Question = ({
  index,
  letter,
  question,
  onChange,
  onKeyDown,
}: QuestionProps): ReactElement => {
  const setAnswer = (e: ChangeEvent<HTMLInputElement>) =>
    onChange({
      questionId: question.id,
      answer: e.target.value,
    });

  return (
    <tr>
      <td>{question.category}</td>
      <td>
        <Input
          data-tag="question-input"
          placeholder={`${letter}...`}
          type="text"
          onChange={setAnswer}
          onKeyDown={(e) => onKeyDown(e, index)}
          defaultValue={question.answer?.string}
        />
      </td>
    </tr>
  );
};

export default Question;
