import { ReactElement } from "react";

import styled from "styled-components";

import Round from "../atoms/Round";
import BlockButton from "../atoms/BlockButton";

import { GQLRound } from "../../../../types";

interface Props {
  rounds: GQLRound[];
  onClick: () => void;
}

const Table = styled.table`
  font-size: 1.25rem;
  text-align: left;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 1.1rem;
    }
  }
`;

const Header = styled.td`
  border: 1px solid #ddd;
  font-weight: 600;
  padding: 0.4rem;
`;

const NonMobileHeader = styled(Header)`
  @media only screen and (max-width: 768px) {
    & {
      display: none;
    }
  }
`;

const Rounds = ({ rounds, onClick }: Props): ReactElement => {
  return (
    <>
      {rounds && rounds.length ? (
        <Table>
          <thead>
            <tr>
              <Header>Human Readable ID (lol)</Header>
              <Header>Started At</Header>
              <NonMobileHeader>Created By</NonMobileHeader>
              <Header>Letter</Header>
              <Header></Header>
            </tr>
          </thead>
          <tbody>
            {rounds.map((round) => (
              <Round round={round} key={round.id} />
            ))}
          </tbody>
        </Table>
      ) : null}
      <h3>
        there are currently{" "}
        <strong>
          {rounds && rounds.length ? `${rounds.length}` : "no rounds"}
        </strong>{" "}
        of Fish/Fruit/Flowers <strong>in progress</strong>
      </h3>
      <p>would you like to start one?</p>
      <BlockButton type="button" onClick={onClick}>
        Start new round
      </BlockButton>
    </>
  );
};

export default Rounds;
