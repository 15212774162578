import { ReactElement } from "react";

import styled, { keyframes } from "styled-components";

export interface ErrorProps {
  message: string;
}

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vw;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  pointer-events: none;
`;

const StyledError = styled.div`
  position: absolute;
  background: #f23f3f;
  color: #fff;
  padding: 1rem;
  border: #000 solid 1px;
  border-radius: 10px;
  text-align: center;
  font-size: 1rem;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  animation: ${fadeOut} 2s 3s forwards;
  margin-bottom: 60vh;

  @media only screen and (max-width: 768px) {
    & {
      margin-bottom: 0;
    }
  }
`;

const Headline = styled.span`
  display: block;
  font-weight: 600;
  line-height: 2;
`;

const Message = styled.span`
  line-height: 2;
`;

const Error = ({ message }: ErrorProps): ReactElement => (
  <Overlay>
    <StyledError>
      <Headline>Error!</Headline>
      <Message>{message}</Message>
    </StyledError>
  </Overlay>
);

export default Error;
