export const GET_USER_QUERY = `
  query GetUser {
    getUser {
      id
      name
      avatar
    }
  }
`;

export const GET_ACTIVE_USERS_QUERY = `
  query GetActiveUsers {
    getActiveUsers {
      id
      name
      avatar
    }
  }
`;

export const GET_USER_HISTORY_QUERY = `
  query GetUserHistory {
    getUser {
      id
      name
      avatar
      scores {
        id
        letter
        started_at
        finishes_at
        winner_id
        created_by
        final_score
        winner {
          id
          name
        }
      }
    }
  }
`;

export const GET_ROUNDS_IN_PROGRESS_QUERY = `
  query GetRoundsInProgress {
    getRoundsInProgress {
      id
      letter
      started_at
      finishes_at
      winner_id
      creator {
        id 
        name
      }
      final_score
    }
  }
`;

export const GET_ROUND_BY_ID_QUERY = `
  query GetRoundById($id: String!) {
    getRoundById(id: $id) {
      id
      letter
      started_at
      finishes_at
      winner_id
      created_by
      final_score
      creator {
        id
        name
      }
      questions {
        id
        round_id
        category
        direction
        answer {
          id
          user_id
          round_id
          question_id
          string
          voted_for_by
          score
          scored
        }
      }
    }
  }
`;

export const GET_ANSWERS_FOR_ROUND_QUERY = `
  query GetAnswersForRound($id: String!) {
    getRoundById(id: $id) {
      id
      letter
      started_at
      finishes_at
      winner {
        id
        name
      }
      creator {
        id
        name
      }
      final_score
      winner {
        id
        name
      }
      marking {
        id
        user {
          id
          name
        }
        round_id
        question {
          id
          category
        }
        string
        voted_for_by
        score
        scored
      }
    }
  }
`;

export const GET_WINNERS_FOR_ROUND_QUERY = `
  query GetAnswersForRound($id: String!) {
    getRoundById(id: $id) {
      id
      letter
      started_at
      finishes_at
      winner {
        id
        name
      }
      creator {
        id
        name
      }
      final_score
      winner {
        id
        name
      }
      answers {
        id
        user {
          id
          name
        }
        round_id
        question {
          id
          category
        }
        string
        voted_for_by
        score
        scored
      }
    }
  }
`;
