import { ReactElement } from "react";

import { useSubscription } from "urql";

import { VOTE_SUBMITTED_SUBSCRIPTION } from "../../graphql/subscriptions";

import UserAnswers from "../molecules/UserAnswers";

import { GQLAnswer, GQLUser } from "../../../../types";

interface Props {
  answers: GQLAnswer[];
  round: string;
  user: GQLUser;
}

const Answers = ({ answers, round, user }: Props): ReactElement => {
  const [{ data: voteSubmittedSubscriptionData }] = useSubscription(
    { query: VOTE_SUBMITTED_SUBSCRIPTION, variables: { id: round } },
    (messages: any = [], response: { voteSubmitted: GQLAnswer[] }) => {
      return [...response?.voteSubmitted, ...messages];
    }
  );

  const scoredAnswers = answers.map((answer) => ({
    ...answer,
    ...(voteSubmittedSubscriptionData ?? []).find(({ id }) => id === answer.id),
  }));

  const currentUser = user.name;

  const players = new Set(
    // @ts-ignore
    scoredAnswers
      ? // @ts-ignore
        scoredAnswers.reduce((memo, { user }) => [...memo, user.name], [])
      : []
  );

  const sections = Array.from(players).map((player) => ({
    user: player as string,
    answers: scoredAnswers
      ? scoredAnswers.filter(({ user }) => user.name === player)
      : [],
  }));

  const idxOne = sections.findIndex(({ user }) => user === currentUser);
  const idxTwo = idxOne + 1 < sections.length ? idxOne + 1 : 0;

  const hasSections = sections && sections[idxOne] && sections[idxTwo];

  return (
    <>
      {hasSections &&
        [sections[idxTwo], sections[idxOne]].map(
          ({ user: player, answers }) => (
            <UserAnswers
              answers={answers}
              user={user}
              player={player}
              key={player}
            />
          )
        )}
    </>
  );
};

export default Answers;
