import { ReactElement } from "react";
import { useHistory, RouteComponentProps } from "react-router-dom";

import styled from "styled-components";

import { useQuery } from "urql";

import { GET_WINNERS_FOR_ROUND_QUERY } from "../graphql/queries";

import Rainbow from "../components/molecules/Rainbow";
import BlockButton from "../components/atoms/BlockButton";

interface Props extends RouteComponentProps<{ id: string }> {}

const GameOver = styled.div`
  background: black
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  color: #fff;
  margin: 1rem;
`;

const Header = styled.th`
  border: 1px solid #fff;
  padding: 0.5rem;
`;

const Cell = styled.td`
  border: 1px solid #fff;
  padding: 0.5rem;
`;

const Winner = ({ match }: Props): ReactElement => {
  const { id } = match.params;

  const [{ data, fetching }] = useQuery({
    query: GET_WINNERS_FOR_ROUND_QUERY,
    variables: { id },
    requestPolicy: "network-only",
  });

  const rscores = data?.getRoundById?.answers?.reduce(
    (acc: Record<string, { name: string; total: number }>, memo: any) => {
      const {
        user: { name },
        score,
      } = memo;

      if (acc[name]) {
        acc[name].total += score;
      } else {
        acc[name] = {
          name,
          total: score,
        };
      }

      return acc;
    },
    {}
  );

  const scores = rscores
    ? (Object.values(rscores) as { name: string; total: number }[]).sort(
        (a, b) => b.total - a.total
      )
    : [];

  const { push } = useHistory();

  const returnHome = () => push("/");

  const hasScores = scores && scores[0];
  const cheating = scores.length === 0;

  const winningScore = hasScores && scores[0].total;

  const champions = hasScores
    ? scores.filter(({ total }) => total === winningScore)
    : [];

  const noOfWinners = champions.length;

  const winners = champions
    .map(({ name }) => (name as string).toUpperCase())
    .join(" & ");

  return fetching ? (
    <div>TABULATING...</div>
  ) : (
    <GameOver>
      <Rainbow
        cheating={cheating}
        winners={winners}
        noOfWinners={noOfWinners}
        winningScore={winningScore}
      />
      <Wrapper>
        {hasScores && (
          <Table>
            <thead>
              <tr>
                <Header>Position</Header>
                <Header>Name</Header>
                <Header>Score</Header>
              </tr>
            </thead>
            <tbody>
              {scores.map(({ name, total }, index) => (
                <tr key={name}>
                  <Cell>{index + 1}</Cell>
                  <Cell>{name}</Cell>
                  <Cell style={{ textAlign: "right" }}> {total}</Cell>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <BlockButton onClick={returnHome}>Let's goooooo (again)</BlockButton>
      </Wrapper>
    </GameOver>
  );
};

export default Winner;
