import { Route, Redirect } from "react-router-dom";

import { useQuery } from "urql";

import { GET_USER_QUERY } from "../../graphql/queries";

export const ProtectedRoute = ({ component: Component, ...args }: any) => {
  const [{ data, fetching }] = useQuery({
    query: GET_USER_QUERY,
    requestPolicy: "network-only",
  });

  return (
    <Route
      {...args}
      render={(props) => {
        if (fetching) {
          return <div />;
        }

        if (data?.getUser?.id) {
          return <Component {...props} user={data.getUser} />;
        }

        return <Redirect to={"/login"} />;
      }}
    />
  );
};
