export const CONNECTION_EVENT = "connection";
export const USER_JOINED = "user-joined";
export const DISCONNECTION_EVENT = "disconnect";
export const NEW_MESSAGE_EVENT = "message";
export const ROUND_START = "round-started";
export const ROUND_END = "round-ended";
export const QUESTIONS_COMPLETE = "questions-complete";
export const ANSWER_VOTED_FOR = "voted-for-answer";
export const WINNER_DECLARED = "winner-declared";
export const NO_WINNER_DECLARED = "no-winner-declared";
export const NEW_MESSAGE = "message";

export const LENGTH_OF_ROUND_IN_MINUTES = 10;

export const CATEGORIES = [
  "Fish",
  "Fruit",
  "Flower",
  "River",
  "Country",
  "City",
  "Vegetable",
  "Animal",
  "Girl's Name",
  "Boy's Name",
  "Male Celebrity",
  "Female Celebrity",
  "Band",
  "English Town/City",
  "Car (make of)",
  "Tube Station",
  "Film",
  "Tree",
  "Dog breed",
  "Brand",
];

export const EMOJIS = [
  "👶",
  "👼",
  "🎅",
  "🤶",
  "🧑",
  "🧝",
  "🧝",
  "🧝",
  "👪",
  "🦌",
  "🍪",
  "🥛",
  "🍷",
  "🍴",
  "⛪",
  "🌟",
  "❄️",
  "☃️",
  "⛄",
  "🔥",
  "🎄",
  "🎁",
  "🧦",
  "🔔",
  "🎶",
];
