import { ReactElement } from "react";
import styled from "styled-components";

import Answer from "../atoms/Answer";

import { GQLUser, GQLAnswer } from "../../../../types";

interface Props {
  answers: GQLAnswer[];
  player: string;
  user: GQLUser;
}

const Table = styled.table`
  text-align: left;
  padding: 1.5rem 2.5rem;
  padding-top: 0;
  width: 100%;

  &:last-of-type {
    padding-bottom: 25vh;
  }

  @media only screen and (max-width: 768px) {
    & {
      padding: 0.25rem 1rem;
    }

    &:last-of-type {
      padding-bottom: 25vh;
    }
`;

const Column = styled.th`
  &:first-of-type {
    width: 150px;
  }

  &:nth-of-type(2) {
    width: calc(100% - 300px);
  }

  &:last-of-type {
    width: 150px;
  }
`;

const UserAnswers = ({ answers, player, user }: Props): ReactElement => (
  <>
    <h3>{player}</h3>
    <Table>
      <thead>
        <tr>
          <Column>Category</Column>
          <Column>Answer</Column>
          <Column>Score</Column>
        </tr>
      </thead>
      <tbody>
        {answers &&
          answers.map((answer) => (
            <Answer key={answer.id} answer={answer} user={user} />
          ))}
      </tbody>
    </Table>
  </>
);

export default UserAnswers;
