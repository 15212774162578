import { ReactElement } from "react";

import styled, { keyframes } from "styled-components";

import Avatar from "../atoms/Avatar";

import { EMOJIS } from "../../constants";

interface UserProps {
  name: string;
  avatar?: string;
}

const bounce = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Spacer = styled.div`
  margin-left: 0.35rem;
`;

const Emoji = styled.div`
  cursor: pointer;
  font-size: 1.25rem;
  padding-bottom: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    animation: ${bounce} 0.5s infinite forwards;
    -webkit-animation: ${bounce} 0.5s infinite forwards;
  }
`;

const User = ({ name, avatar }: UserProps): ReactElement => {
  const index = Math.floor(Math.random() * EMOJIS.length);

  return (
    <Emoji>
      {avatar ? (
        <Wrapper>
          <Avatar src={avatar} isMini />
          <Spacer>{`${name} is here!`}</Spacer>
        </Wrapper>
      ) : (
        `${EMOJIS[index]} ${name} is here!`
      )}
    </Emoji>
  );
};

export default User;
