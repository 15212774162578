import { ReactElement, createContext } from "react";

import Cookies from "js-cookie";

const clearExpressCookies = (): void => {
  Cookies.remove("FishFruitFlowersSessionId");
};

export const UserControlContext = createContext({
  uploadAvatar: async (_: File) => Promise.resolve(),
  signInUser: async (_: string, __: string) => Promise.resolve(),
  signOutUser: async () => Promise.resolve(),
});

export function UserControlProvider({ children }: { children: ReactElement }) {
  const uploadAvatar = async (file: File): Promise<void> => {
    if (!file) return;

    const formData = new FormData();

    formData.append("file", file);

    const response = await fetch("/api/user/avatar", {
      method: "POST",
      body: formData,
    });

    if (response.status >= 200 && response.status <= 299) {
      return;
    } else {
      throw new Error("Failed to upload avatar for user");
    }
  };

  const signInUser = async (name: string, password: string): Promise<void> => {
    const response = await fetch("/api/login", {
      method: "POST",
      body: JSON.stringify({ name, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status >= 200 && response.status <= 299) {
      return;
    } else if (response.status === 403) {
      throw new Error("Incorrect username or password");
    } else {
      throw new Error("Something has gone horribly wrong");
    }
  };

  const signOutUser = async (): Promise<void> => {
    clearExpressCookies();
  };

  return (
    <UserControlContext.Provider
      value={{
        uploadAvatar,
        signInUser,
        signOutUser,
      }}
    >
      {children}
    </UserControlContext.Provider>
  );
}
