import { ReactElement } from "react";
import { format } from "date-fns";

import styled from "styled-components";
import readable from "uuid-readable";

import { GQLRound } from "../../../../types";

interface RoundProps {
  round: GQLRound;
}

const Cell = styled.td`
  border: 1px solid #ddd;
  padding: 0.4rem;
`;

const NonMobileCell = styled(Cell)`
  @media only screen and (max-width: 768px) {
    & {
      display: none;
    }
  }
`;

const SmallCell = styled(Cell)`
  font-size: 0.8rem;
`;

const Round = ({ round }: RoundProps): ReactElement => (
  <tr>
    <SmallCell>{readable.generate(round.id)}</SmallCell>
    <Cell>
      {round.started_at
        ? format(new Date(parseInt(round.started_at as string)), "hh:mm aa")
        : "Not yet"}
    </Cell>
    <NonMobileCell>{round.creator?.name}</NonMobileCell>
    <Cell>{round.started_at ? round.letter : "?"}</Cell>
    <Cell>
      <a href={`/round/${round.id}`}>Join in!</a>
    </Cell>
  </tr>
);

export default Round;
