import { StrictMode, ReactElement } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Provider } from "urql";

import { client } from "./graphql/client";

import LandingPage from "./pages/Landing";
import LoginPage from "./pages/Login";
import LogoutPage from "./pages/Logout";
import RoundPage from "./pages/Round";
import ResultsPage from "./pages/Results";
import WinnerPage from "./pages/Winner";
import ProfilePage from "./pages/Profile";

import { UserControlProvider } from "./components/context/UserControlStore";
import { ProtectedRoute } from "./components/utils/ProtectedRoute";

import "./index.css";

const App = (): ReactElement => (
  <StrictMode>
    <UserControlProvider>
      <Provider value={client}>
        <Router>
          <Switch>
            <Route component={LoginPage} exact path={"/login"} />
            <Route component={LogoutPage} exact path={"/logout"} />
            <ProtectedRoute component={LandingPage} exact path={"/"} />
            <ProtectedRoute component={ProfilePage} exact path={"/me"} />
            <ProtectedRoute component={RoundPage} exact path={"/round/:id"} />
            <ProtectedRoute
              component={ResultsPage}
              exact
              path={"/round/:id/results"}
            />
            <ProtectedRoute
              component={WinnerPage}
              exact
              path={"/round/:id/winner"}
            />
          </Switch>
        </Router>
      </Provider>
    </UserControlProvider>
  </StrictMode>
);

ReactDOM.render(<App />, document.getElementById("root"));
